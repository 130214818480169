import { Injectable } from '@angular/core';
import { ApiResponse, Message, ChatPageModel } from '@models';
import { BaseService } from '@utils';

@Injectable({
  providedIn: 'root',
})
export class ChatService extends BaseService {
  getVisitor(filter: Record<string, unknown>) {
    return this.httpClient.get<ApiResponse<ChatPageModel.VisitorData['data']>>(
      `visitors/source`,
      {
        params: this.convertToHttpParams(filter),
      }
    );
  }
  getMessages(filter: Record<string, unknown>, visitorId: string) {
    return this.httpClient.get<ApiResponse<Message[]>>(
      `visitors/${visitorId}/messages`,
      {
        params: this.convertToHttpParams(filter),
      }
    );
  }
}
